<template>
  <div>
    <div class="table__spinnerWrapper" v-if="itemsLoading">
      <app-spinner></app-spinner>
    </div>

    <v-container id="regular-tables" fluid tag="section" class="px-15" v-else>
      <v-row>
        <v-col md="4">
          <v-text-field :dark="$store.state.isDarkMode" type="date" dense outlined color="#757575" :label="$t('date')"
           v-model="date"></v-text-field>
        </v-col>
        <v-col md="8" class="d-flex justify-end">
          <v-btn
            v-if="returnAbility('absent:store')"
            color="green"
            class="white--text"
            depressed
            rounded
            @click="
              $emit('goToAbsentEdit', {
                absents,
                date,
              })
            "
          >
            <v-icon>mdi-plus</v-icon>
            {{ $t("edit") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-simple-table :dark="$store.state.isDarkMode">
        <thead class="table-heading">
          <tr>
            <th v-for="(name, i) in tableHeaders" :key="i">
              {{ $t(name) }}
            </th>
          </tr>
        </thead>
        <v-fade-transition mode="out-in" :group="true" tag="tbody">
          <template v-for="(absent, idx) in absents">
            <tr :key="absent.registration_record_id" v-if="absent.absent">
              <td>{{ idx + 1 }}</td>
              <td>{{ absent.name }}</td>
              <td>{{ absent.localeType }}</td>
              <td>{{ absent.note }}</td>
            </tr>
          </template>
        </v-fade-transition>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      eduClassId: this.$route.params.eduClassId,
      dateDialog: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      tableHeaders: ["id", "student name", "reason", "notes"],
      absents: [],
      submitBtnLoading: false,
      itemsLoading: false,
    };
  },
  watch: {
    date() {
      this.fetchAbsents();
    },
  },
  methods: {
    async fetchAbsents() {
      try {
        this.itemsLoading = true;
        const absents = await axios.get("/absent", {
          params: {
            edu_class_id: this.eduClassId,
            date: this.date,
          },
        });
        this.absents = [...absents.data.data];
        this.absents.forEach((el) => {
          if (el.type == "justified") {
            el.localeType = "غياب مبرر";
          } else {
            el.localeType = "غياب غير مبرر";
          }
        });

        console.log("absents:", absents);
      } catch (err) {
        console.log("err", err);
      } finally {
        this.itemsLoading = false;
      }
    },
  },
  async created() {
    this.fetchAbsents();
  },
};
</script>

<style>
.hilightRow {
  background: rgb(250, 234, 234);
}
</style>